<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNav section -->
        <HeaderNav></HeaderNav>

        <!-- Breadcrumb section -->
        <BreadcrumbSection breadcrumbDetail="Unlock innovation for every business with the power of Riset.ai" breadcrumbName="terms_of_use" breadcrumbTitle="Terms and Conditions"></BreadcrumbSection>

        <!-- Product section -->
        <!-- <Product></Product> -->

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import BreadcrumbSection from '@/components/BreadcrumbSection.vue'
import AboutUsDesc from '@/components/AboutUsDesc.vue'

export default {
    components: {
        HeaderNav,
        BannerTwo,
        FooterSection,
        BreadcrumbSection,
        AboutUsDesc
    }
}
</script>
